@import '../App.css';

/****Sidebar CSS***/

.sideBarButton{
    display:flex;
    flex-direction:row;
    gap:15px;
    align-items:center;
    box-sizing:border-box;
    padding:8px 14px;
    height:44px;
    width:100%;
    background-color:var(--white);
    color:var(--grey-color);
    border-radius:8px;
    transition: background-color 0.5s ease;
    user-select: none;
    &:hover{
        background-color: var(--primary-color-hover);
        
    }
    svg{
        width:25px;
        height:auto;
    }
    span{
        font-weight:600;
        font-size: 14px;
    }
}

.activeSideBarButton{
    background-color:var(--primary-color);
    color:var(--white);
    &:hover{
        background-color: var(--primary-color);
        
    }
}

.sideBarButtonDropDownContainer{
    display:flex;
    flex-direction:column;
    box-sizing:border-box;
    padding:0px 0px 0px 18px;
    gap:3px;
    overflow:hidden;
    max-height:0px;
    transition: max-height 0.4s ease;
    
}

.activeSideBarButtonDropDownContainer{
    max-height:500px;
    margin-top: 10px;
}

.sideBarButtonDropDownItem{
    display:flex;
    flex-direction:row;
    width:100%;
    gap:10px;
    padding:10px 14px;
    border-radius:8px;
    box-sizing:border-box;
    transition: background-color 0.5s ease;
    user-select:none;
    font-weight:600;
    color:#5a5a5a;
    &:hover{
        background-color:#80808036;
    }
    span{
        font-size: 14px;
    }
}

.activeSideBarButtonDropDownItem{
    background-color:#80808036;
    color:inherit;
}
